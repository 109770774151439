import React from 'react';
import { Card } from 'react-bootstrap';

import AppConstants from '../app/constants';
import CardTable from '../components/Table/CardTable';
import FranceMap from '../components/FranceMap';
import PageTitle from '../components/layout/PageTitle/PageTitle';
import SEO from '../components/seo';

import departmentsReaCapacity from '../data/dep_rea_capa.json';

const DepartmentsPage = ({ franceStats }) => {

  const { departments } = franceStats;

  const departmentsData = Object.values(departments).map((department) => ({
    ...department,
    rea_capacity: departmentsReaCapacity[department.code].rea_capacity,
    percentRea: `${Math.round((department.totalRea / departmentsReaCapacity[department.code].rea_capacity) * 100)}%`
  }));

  return (
    <>
      <SEO title="Evolution du Coronavirus en France par département" />

      <PageTitle title="Tous les départements" />

      <Card>
        <Card.Body>
          <FranceMap departements={franceStats.mapDepartments} />
        </Card.Body>
      </Card>

      <CardTable
        headers={['', '', 'Total', 'Décès', 'Hosp', 'Réa', 'Rétablis', 'Capa Réa', '']}
        dataKeys={['code', 'nom', 'total', 'totalDc', 'totalHosp', 'totalRea', 'totalRad', 'rea_capacity', 'percentRea']}
        data={departmentsData.sort((a, b) => a.code - b.code)}
        pathBase={AppConstants.ROUTE_PATHS.departments}
      />
    </>
  );
};

export default DepartmentsPage;
